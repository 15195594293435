import React, {useState} from "react";
import Button from "./button";
import EmailInput from "./EmailInput";

import {CPCheckbox} from "./contact-pigeon-form";
import subscribeToNewsletter from "../utils/subscribeToNewsLetter";
function NewsletterForm({closeButton = undefined, isHorizontal = true}) {
  const [isAgreed, setIsAgreed] = useState({value: false, error: ""});
  const [email, setEmail] = useState({value: "", error: ""});
  const [processing, setProcessing] = useState(false);
  const [subscribeResult, setSubscribeResult] = useState(undefined);
  const onEmailChange = (e) => {
    setEmail({...email, value: e.target.value});
  };

  const onSubscribe = async () => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.value
      )
    ) {
      setEmail({...email, error: "Invalid email"});
    } else {
      setEmail({...email, error: ""});
    }
    if (!isAgreed.value) {
      setIsAgreed({...isAgreed, error: "Please accept"});
    } else {
      setIsAgreed({...isAgreed, error: false});
    }
    if (!email.error && !isAgreed.error && email.value && isAgreed.value) {
      setProcessing(true);
      try {
        await subscribeToNewsletter(email.value, isAgreed.value);
        setSubscribeResult("success");
        setEmail({value: "", error: ""});
        setIsAgreed({value: false, error: ""});
      } catch (e) {
        setSubscribeResult("error");
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <div
      className={`relative w-full  mx-auto mt-16 lg:mt-20vh bg-white p-10 ${
        isHorizontal
          ? "p8 grid grid-cols-2 border-2 border-primary border-solid"
          : "md:w-2/3 lg:w-1/3"
      }`}
      style={!isHorizontal ? {boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)"} : {}}
    >
      <div>
        <h2 className="text-primary text-4xl font-bold mb-4">
          {subscribeResult === "success"
            ? "Thanks for subscribing"
            : "Subscribe to newsletter"}
        </h2>
        {closeButton && closeButton()}
        {subscribeResult === "error" ? (
          <p className="text-sm mb-8">
            Error occurred while subscribing, please try again
          </p>
        ) : subscribeResult === "success" ? null : (
          <p className="text-sm mb-8">
            Get a bi-monthly email with news, interesting reads, and useful tips
            about startups.
          </p>
        )}
      </div>
      {subscribeResult !== "success" && (
        <div className="previewForm">
          <div className="flex flex-col">
            {!isHorizontal && (
              <label for="sfemail" className="text-primary font-medium mb-4">
                Email Address{" "}
              </label>
            )}
            <EmailInput
              className={email.error ? "" : isHorizontal ? "mb-4" : "mb-10"}
              onInput={onEmailChange}
              value={email.value}
            />
            {email.error && (
              <span className="text-primary mb-4  text-sm">{email.error}</span>
            )}
            <div className="flex justify-between items-baseline">
              <div className="flex flex-col text-sm">
                <CPCheckbox
                  isAgreed={isAgreed.value}
                  setIsAgreed={() => {
                    setIsAgreed({
                      value: !isAgreed.value,
                      error: !isAgreed.value ? "" : isAgreed.error,
                    });
                  }}
                />
                {isAgreed.error && (
                  <span className="text-primary mb-4">{isAgreed.error}</span>
                )}
              </div>
              <Button
                className="w-40"
                secondary={true}
                onClick={() => onSubscribe()}
                disabled={processing}
              >
                {processing ? "Subscribing..." : "Subscribe"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default NewsletterForm;
