import axios from "axios";

const subscribeToNewsletter = async (email: string, checked: boolean) => {
  var form = new FormData();
  form.append("gm_pid", "9774"); //SignUp Form ID
  form.append("is_silentcall", "yes"); //Mandatory always yes in this case
  form.append("sfemail", email); //The subscriber's email add
  form.append("sfcrcheckbox", checked ? "1" : "0"); //Mandatory always 1 in this case
  var settings = {
    timeout: 0,
    headers: {},
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
  };

  try {
    const response = await axios.post(
      "https://www.contactpigeon.com/apps/en/stem/silentcall.html/",
      form,
      settings
    );
  } catch (e) {
    console.error(e);
  }
};

export default subscribeToNewsletter;
