import React from "react";

const EmailInput = ({className, ...others}) => {
  return (
    <input
      {...others}
      type="text"
      className={`h-11 border-2 border-solid border-primary focus:border-primary px-2 ${className}`}
      placeholder="Type your email address here"
    />
  );
};

export default EmailInput;
