import React from "react";
import EmailInput from "../components/EmailInput";
import Checkbox from "../components/checkbox/checkbox";
import Button from "./button";
import {Link} from "gatsby";
const ContactPigeonForm = ({children, className = ""}) => (
  <form
    name="form_sf_9774"
    method="post"
    action="https://forms.cp.works/apps/en/stem/subscribe.html/"
    className={className}
  >
    <input type="hidden" name="gm_pid" value="9774" />

    {children}
  </form>
);

export const CPEmail = ({className = ""}) => (
  <EmailInput
    id="sfemail"
    name="sfemail"
    type="email"
    className={className}
    required
  />
);

export const CPCheckbox = ({className = "", isAgreed, setIsAgreed}) => (
  <Checkbox
    id="sfcrcheckbox"
    name="sfcrcheckbox"
    isChecked={isAgreed}
    onChange={() => setIsAgreed(!isAgreed)}
    bordered
    text={
      <>
        I agree with the{" "}
        <Link to="/privacy-policy" className="hover:underline text-primary">
          T&amp;C
        </Link>
      </>
    }
    required
    className={className}
  />
);

export const CPSubmitButton = ({
  className = "",
  processing = false,
  isAgreed = false,
}) => (
  <Button
    secondary={true}
    type="submit"
    name="regme"
    className={className}
    disabled={processing || !isAgreed}
  >
    Subscribe
  </Button>
);

export default ContactPigeonForm;
